<style lang="less" scoped>
.box {
    flex: 1;
    background-color: #F5F5F5;
    display: grid;
    grid-template-columns: 215px 1fr;
    grid-gap: 16px;
    padding: 16px 0;
}

.tabs {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
}

.tab {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
}

.tab_icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.tab_text {
    font-size: 14px;
    line-height: 20px;
    color: #222;
}

.active {
    color: #FF4000;
    font-weight: bold;
}


.content_box {
    overflow: hidden;

    /deep/ .lists-page{ text-align: right }

    /deep/ .el-table {
        border: 1px solid #EEEFF0; border-radius: 4px; overflow: hidden;
    }

    /deep/ .el-table::before {
        display: none;
    }

    /deep/ .el-table th {
        background-color: #F8F9FA;
    }

    /deep/ .el-table th.is-leaf {
        border-color: #EEEFF0;
    }

    /deep/ .el-table th .cell {
        color: #222222;
        font-size: 12px;
        font-weight: bold;
    }

    /deep/ .el-table td {
        border-bottom: 1px solid #EEEFF0;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FFECE5;
        border: 1px solid #FF791F;
        color: #FF791F;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #FF791F;
    }

   /deep/ .select-input{
      .el-input__inner{
        border-radius: 4px; padding-left: 140px;
        &:focus{ border-color: #FF791F; }
      }
      label{ line-height: 32px; color: #999; padding: 0 10px; width: 60px;  }
   }
}
</style>
<template>
    <div class="box">
        <div class="tabs">
            <div v-for="(item, index) in tabs" :key="index" class="tab"
                :class="$route.path == item.route ? 'active' : ''" @click="routeFn(item, index)">
                <img :src="OSS + '/icon' + index + ($route.path == item.route ? '_active' : '') + '.png'"
                    class="tab_icon">
                <div>{{ item.text }}</div>
            </div>
        </div>
        <div class="content_box">
            <router-view />
        </div>
        <service />
    </div>
</template>
<script>
import service from './service';
export default {
    name: 'returnOrder',
    components: { service },
    mounted: function () {

    },
    data: function () {
        return {
            tabs: [
                { text: '退货运费抽佣', route: '/returnOrder/commission' },
                { text: '退货订单明细', route: '/returnOrder/details' },
                { text: '退货返佣汇总', route: '/returnOrder/gather' },
                { text: '基础信息', route: '/returnOrder/baseInfo' },
            ]
        }
    },
    methods: {
        routeFn(item, index) {
            this.active = index;
            this.$router.push(item.route);
        }
    },
}
</script>